import axios from 'axios';

class Newsletter {
  constructor(options) {

    var defaults = {
      messages: {
        success: 'Completed',
        successLoggedIn: 'You have been successfully subscribed',
        unsubscribe: 'You have been successfully unsubscribed',
        failure: 'Failed'
      }
    };
    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.selector);
    if (!this.container) {
      return;
    }
    this.form = this.container.querySelector('form');
    this.endpoint = this.form.getAttribute('action');
    this.modal = document.querySelector('#newsletter-modal');

    this.addEvents();
  }

  addEvents() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submit();
    });

    this.form.addEventListener('focus', (e) => {

      if (this.form.classList.contains('focused')) {
        return;
      }

      if (e.target.tagName === 'INPUT' && e.target.type === 'email') {
        this.form.classList.add('focused');
      }

    }, true);

  }

  submit() {

    var data = this.getFormData();

    const token = window.localStorage.getItem('memberToken');
    const method = data.get('method');

    axios.post(this.endpoint, data, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {

        if (response.data && response.data.message) {
          this.displayMessage(response.data.message);
        }
        else if (response.data.success) {
          if (method === 'subscribe' && token) {
            this.displayMessage(this.params.messages.successLoggedIn);
          }
          else if (method === 'subscribe' && !token) {
            this.displayMessage(this.params.messages.success);
          }
          else if (method === 'unsubscribe') {
            this.displayMessage(this.params.messages.unsubscribe);
          }
        } else {
          this.displayMessage(this.params.messages.failure);
        }
      })
      .catch((error) => {
        this.displayMessage(this.params.messages.failure);
      }).finally(() => {
        const event = new CustomEvent('dpgNewsletterUpdate');
        this.container.dispatchEvent(event);
      });
  }

  getFormData() {
    var data = new FormData();
    this.form.querySelectorAll('input').forEach((element) => {
      data.append(element.name, element.value);
    });

    const hit_source = localStorage.getItem('nb_hit_source');
    const hit_medium = localStorage.getItem('nb_hit_medium');
    const hit_campaign = localStorage.getItem('nb_hit_campaign');

    if (hit_source) {
      data.append('tags[]', 'source:' + hit_source);
    }
    if (hit_medium) {
      data.append('tags[]', 'medium:' + hit_medium);
    }

    return data;
  }

  displayMessage(message) {

    if (!this.modal) {
      return;
    }

    this.modal.querySelector('#newsletter-module-response').textContent = message;
    this.modal.showModal();
    window.setTimeout(() => {
      this.modal.close();
      this.modal.querySelector('#newsletter-module-response').textContent = '';
    }, 5000);
  }


}

export {
  Newsletter as
    default
};
