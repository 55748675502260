import axios from 'axios';

class Auth {

    constructor() {
        this._profile = null;
        this._error = '';
    }

    get profile() {
        return this._profile;
    }

    get isLoggedIn() {
        return this._profile ? true : false;
    }

    get message() {
        return this._message;
    }

    get error() {
        return this._error;
    }

    async getProfile() {

        const token = window.localStorage.getItem('memberToken');
        let result = false;

        if (token) {

            try {
                const response = await axios.get('/members?task=member.profile&format=json', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });
                this._profile = response.data.data;
                result = true;
            } catch (error) {
                this._error = error.response.data.message;
                result = false;
            }

        }

        const event = new CustomEvent('dpgAuthReady');
        window.dispatchEvent(event);

        return result;
    }

    async login(email, password) {

        let result = false;

        try {
            const response = await axios.post('/members?task=member.login&format=json', {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            window.localStorage.setItem('memberToken', response.data.data.key);
            result = true;
            const event = new CustomEvent('dpgAuthLogin');
            window.dispatchEvent(event);
        } catch (error) {
            this._error = error.response.data.message;
            result = false;
        }

        return result;
    }

    logout() {
        window.localStorage.removeItem('memberToken');
        this._profile = null;
        const event = new CustomEvent('dpgAuthLogout');
        window.dispatchEvent(event);
    }

    async resetPasswordRequest(email, recaptcha) {

        let result = false;

        try {
            const response = await axios.post('/members?task=member.resetRequest&format=json', {
                email,
                recaptcha
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            result = true;
        } catch (error) {
            this._error = error.response.data.message;
            result = false;
        }

        return result;
    }

    async resetPasswordComplete(email, token, password1, password2) {

        let result = false;

        try {
            const response = await axios.post('/members?task=member.resetComplete&format=json', {
                email,
                token,
                password1,
                password2
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            result = true;
            this._message = response.data.message;
        } catch (error) {
            this._error = error.response.data.message;
            result = false;
        }

        return result;
    }
}

export default new Auth();


