import axios from 'axios';
import auth from './auth';
import Newsletter from './newsletter';

class Newsletters {

    constructor(options) {

        const defaults = {
            selector: '.newsletter-box',
            messages: {
                success: 'Completed',
                successLoggedIn: 'You have been successfully subscribed',
                unsubscribe: 'You have been successfully unsubscribed',
                failure: 'Failed'
            }
        };

        this.params = Object.assign({}, defaults, options || {});
        this.profile = {};
        this.status = [];

        this.newsletters = document.querySelectorAll(this.params.selector);
        this.start();
    }

    start() {

        for (let index = 0; index < this.newsletters.length; index++) {
            const newsletter = this.newsletters[index];
            newsletter.addEventListener('dpgNewsletterUpdate', (e) => {
                this.render();
            });
        }

        this.addEvents();
    }

    addEvents() {

        window.addEventListener('dpgAuthReady', async () => {
            await this.render();
        });

        window.addEventListener('dpgAuthLogout', () => {
            this.renderGuest();
        });

    }

    async render() {
        if (auth.isLoggedIn) {
            this.profile = auth.profile;
            await this.getStatus();
            this.renderLoggedIn();
        } else {
            this.renderGuest();
        }
    }

    renderGuest() {
        this.newsletters.forEach(newsletter => {
            const clone = document.querySelector('#newsletter-guest-template-' + newsletter.id).content.cloneNode(true);
            newsletter.innerHTML = '';
            newsletter.appendChild(clone);
            new Newsletter({
                selector: '#' + newsletter.id,
                messages: this.params.messages
            });
        });
    }

    async renderLoggedIn() {
        this.newsletters.forEach(newsletter => {
            const clone = document.querySelector('#newsletter-loggedin-template-' + newsletter.id).content.cloneNode(true);
            clone.querySelector('input[type="email"').value = this.profile.email;
            clone.querySelector('input[type="email"').readOnly = true;

            const listId = clone.querySelector('input[name="listId"]').value;
            const status = this.status.find(item => item.list === listId);

            if (status && status.subscribed) {
                clone.querySelector('button').textContent = this.params.unsubscribeLabel;
                clone.querySelector('input[name="method"]').value = 'unsubscribe';
            } else {
                clone.querySelector('button').textContent = this.params.subscribeLabel;
                clone.querySelector('input[name="method"]').value = 'subscribe';
            }

            newsletter.innerHTML = '';
            newsletter.appendChild(clone);

            new Newsletter({
                selector: '#' + newsletter.id,
                messages: this.params.messages
            });
        });
    }

    async getStatus() {

        const token = window.localStorage.getItem('memberToken');

        if (!token) {
            return;
        }

        try {

            const response = await axios.get('/members?task=member.isSubscribed&format=json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            this.status = response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export {
    Newsletters as
        default
};
