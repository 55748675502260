import axios from 'axios';
import auth from './auth';

class Account {

    constructor(options) {

        const defaults = {
            containerSelector: '#members-account-page',
            editProfileTemplateSelector: '#members-edit-profile-template',
            modalSelector: '#members-account-messages',
        };
        this.params = Object.assign({}, defaults, options || {});
        this.container = document.querySelector(this.params.containerSelector);
        this.editProfileTemplate = document.querySelector(this.params.editProfileTemplateSelector);

        this.start();
    }

    async start() {

        window.addEventListener('dpgAuthReady', () => {
            if (auth.isLoggedIn) {
                this.renderEditProfileView();
            } else {
                window.location = window.location.protocol + '//' + window.location.host;
            }
        });

        window.addEventListener('dpgAuthLogout', () => {
            window.location = window.location.protocol + '//' + window.location.host;
        });
    }

    async save(data) {

        const token = window.localStorage.getItem('memberToken');

        if (!token) {
            return false;
        }

        let response;
        try {
            response = await axios.post('/members?task=member.save&format=json', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            });

        } catch (error) {
            this.displayMessage(error.response.data.message);
            return false;
        }

        this.displayMessage(response.data.message);

        return true;
    }

    renderEditProfileView() {

        if (!this.editProfileTemplate) {
            return;
        }


        const clone = this.editProfileTemplate.content.cloneNode(true);
        clone.getElementById('jform_id').value = auth.profile.id;
        clone.getElementById('jform_name').value = auth.profile.name;
        clone.getElementById('jform_nickname').value = auth.profile.nickname;
        clone.getElementById('jform_username').value = auth.profile.username;
        clone.getElementById('jform_email1').value = auth.profile.email;

        const saveButton = clone.querySelector('#members-save-profile-button');
        const profileForm = clone.querySelector('form');
        profileForm.addEventListener('submit', async (event) => {
            event.preventDefault();
            saveButton.disabled = true;
            const formData = new FormData();
            const inputs = profileForm.querySelectorAll('input');
            inputs.forEach(input => {
                const name = input.name;
                const value = input.value;
                formData.append(name, value);
            });

            await this.save(formData);
            saveButton.disabled = false;
        });

        const exportButton = clone.querySelector('#members-export-account-data-button');
        exportButton.addEventListener('click', async (event) => {
            event.preventDefault();
            exportButton.disabled = true;
            await this.requestExport();
            exportButton.disabled = false;
        });

        const removeButton = clone.querySelector('#members-remove-account-button');
        removeButton.addEventListener('click', async (event) => {
            event.preventDefault();
            removeButton.disabled = true;
            await this.requestRemove();
            removeButton.disabled = false;
        });

        this.container.innerHTML = '';
        this.container.appendChild(clone);

        this.modal = document.querySelector(this.params.modalSelector);

    }

    displayMessage(message) {

        if (!this.modal) {
            return;
        }

        this.modal.querySelector('span').textContent = message;
        this.modal.showModal();
        window.setTimeout(() => {
            this.modal.close();
        }, 5000);
    }

    async requestExport() {

        const token = window.localStorage.getItem('memberToken');

        if (!token) {
            return false;
        }

        let response;

        try {
            response = await axios.post('/members?task=member.requestExport&format=json', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
        } catch (error) {
            this.displayMessage(error.response.data.message);
            return false;
        }

        this.displayMessage(response.data.message);

        return true;
    }

    async requestRemove() {

        const token = window.localStorage.getItem('memberToken');

        if (!token) {
            return false;
        }

        let response;

        try {
            response = await axios.post('/members?task=member.requestRemove&format=json', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
        } catch (error) {
            this.displayMessage(error.response.data.message);
            return false;
        }

        this.displayMessage(response.data.message);

        return true;
    }
}

export {
    Account as
        default
};
