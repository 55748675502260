import Header from './modules/header';
import Carousel from './modules/carousel';
import Map from './modules/map';
import Newsletter from './modules/newsletter';
import Newsletters from './modules/newsletters';
import Gallery from './modules/gallery';
import Tail from './modules/tail';
import Tabs from './modules/tabs';
import Widget from './modules/widget';
import Global from './modules/global';
import Commercial from './modules/commercial';
import Cmp from './modules/cmp';
import Multiplex from './modules/multiplex';
import ScrollTracker from './modules/scrolltracker';
import Popular from './modules/popular';
import Textlinks from './modules/textlinks';
import Livenow from './modules/livenow';
import ArticleLatestNews from './modules/articlelatestnews';
import ReadingList from './modules/readinglist';
import Members from './modules/members';
import Account from './modules/account';
import TextToSpeech from './modules/tts';
import RFVTracker from './modules/rfv';
import CommentedItems from './modules/commenteditems';


const modules = {
  Header,
  Carousel,
  Map,
  Newsletter,
  Newsletters,
  Gallery,
  Tail,
  Tabs,
  Widget,
  Global,
  Commercial,
  Cmp,
  Multiplex,
  ScrollTracker,
  Popular,
  Textlinks,
  Livenow,
  ArticleLatestNews,
  ReadingList,
  CommentedItems,
  Members,
  Account,
  TextToSpeech,
  RFVTracker
};

const scripts = document.querySelectorAll('.joomla-script-options');
if (scripts.length > 0) {

  for (let index = 0; index < scripts.length; index++) {
    const script = scripts[index];
    const options = JSON.parse(script.text || script.textContent);
    if (options.modules) {
      options.modules.forEach((module) => {
        if (modules[module.name]) {
          new modules[module.name](module.options);
        }
      });
    }
  }
}
