import axios from 'axios';
import auth from './auth';

class ReadingList {

    constructor(options) {
        const defaults = {
            containerSelector: '#read-later-list',
            templateSelector: '#read-later-item-template',
            buttonSelector: '.read-later button',
            modalSelector: '#read-later-modal',
            gridClass: 'grid-cards',
            bookmarkAddedMessage: 'Το άρθρο προστέθηκε στη λίστα σας',
            bookmarkRemovedMessage: 'Το άρθρο αφαιρέθηκε από τη λίστα σας',
            noBookmarksMessage: 'Δεν έχετε αποθηκευμένα άρθρα'
        };
        this.params = Object.assign({}, defaults, options || {});
        this.container = document.querySelector(this.params.containerSelector);
        this.template = document.querySelector(this.params.templateSelector);
        this.button = document.querySelector(this.params.buttonSelector);
        this.modal = document.querySelector(this.params.modalSelector);
        this.storageKey = 'nbReadingList';
        this.saving = false;
        this.start();
    }

    start() {

        if (this.container) {
            this.renderList();
        }

        if (this.button) {

            const list = this.getList();
            const exists = list.find((entry) => entry.id == this.button.dataset.id);
            if (exists) {
                this.button.classList.add('saved');
            }

            this.button.addEventListener('click', async (event) => {
                event.preventDefault();
                if (this.saving) {
                    return;
                }
                this.saving = true;
                if (this.button.classList.contains('saved')) {
                    this.removeFromList(this.button.dataset.id);
                } else {
                    await this.addToList(this.button.dataset.id, this.button.dataset.type);
                }
                this.saving = false;
                this.renderList();
            });
        }

        window.addEventListener('dpgAuthReady', () => {
            if (auth.isLoggedIn) {
                this.syncFromServer();
            }
        });


    }

    async addToList(id, type) {

        const url = `https://${window.location.host}/index.php?option=com_news&view=${type}&id=${id}&format=json`;
        let response;
        try {
            response = await axios.get(url);
        } catch (error) {
            console.log(error);
            return;
        }

        const item = response.data.data;

        if (auth.isLoggedIn) {

            const token = window.localStorage.getItem('memberToken');

            try {
                await axios.post('/members?task=bookmarks.save&format=json', {
                    itemId: item.id,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                });
            } catch (error) {
                console.log(error);
            }
        }

        const list = this.getList();

        const exists = list.find((entry) => entry.id == id);

        if (!exists) {
            list.push({
                id: item.id,
                title: item.title,
                link: item.link,
                category: item.category,
                image: item.fields.image
            });
            this.saveList(list);
            this.button.classList.add('saved');
            this.displayMessage(this.params.bookmarkAddedMessage);

            if (typeof window.gtag === 'function') {
                gtag('event', 'read-later-added');
            }
        }


    }

    async removeFromList(id) {


        if (auth.isLoggedIn) {

            const token = window.localStorage.getItem('memberToken');

            try {
                await axios.post('/members?task=bookmarks.delete&format=json', {
                    itemId: id,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                });
            } catch (error) {
                console.log(error);
            }
        }

        const list = this.getList();
        const index = list.findIndex((entry) => entry.id == id);
        if (index !== -1) {
            list.splice(index, 1);
            this.saveList(list);

            if (this.button) {
                this.button.classList.remove('saved');
            }

            this.displayMessage(this.params.bookmarkRemovedMessage);

            if (this.container) {
                this.renderList();
            }

            if (typeof window.gtag === 'function') {
                gtag('event', 'read-later-removed');
            }
        }
    }

    getList() {
        const value = window.localStorage.getItem(this.storageKey);
        if (!value) {
            return [];
        }
        return JSON.parse(value);
    }

    saveList(list) {
        window.localStorage.setItem(this.storageKey, JSON.stringify(list));
    }

    renderList() {

        if (!this.template) {
            return;
        }
        const list = this.getList();
        list.reverse();

        this.container.innerHTML = '';
        this.container.classList.remove(this.params.gridClass);

        if (list.length === 0) {
            this.container.innerHTML = '<p>' + this.params.noBookmarksMessage + '</p>';
            return;
        }

        this.container.classList.add(this.params.gridClass);

        for (let index = 0; index < list.length; index++) {
            const item = list[index];
            const clone = this.template.content.cloneNode(true);
            clone.querySelector('.item-category').textContent = item.category;
            clone.querySelector('.item-image').src = item.image;
            clone.querySelector('.image-link').href = item.link;
            clone.querySelector('.item-link').href = item.link;
            clone.querySelector('.item-link').textContent = item.title;
            clone.querySelector('.item-delete-bookmark-button').dataset.id = item.id;
            this.container.appendChild(clone);
        }

        this.container.addEventListener('click', (e) => {
            const { target } = e;
            if (target.matches('.item-delete-bookmark-button')) {
                this.removeFromList(target.dataset.id);
            }

        });

    }

    async syncFromServer() {

        const token = window.localStorage.getItem('memberToken');

        try {

            const response = await axios.get('/members?task=bookmarks.fetch&format=json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            });

            const serverItems = response.data.data.items;
            const browserItems = this.getList();

            // Push browser items to server
            for (let index = 0; index < browserItems.length; index++) {

                const item = browserItems[index];

                const exists = serverItems.find((entry) => entry.id == item.id);

                if (!exists) {
                    try {
                        await axios.post('/members?task=bookmarks.save&format=json', {
                            itemId: item.id,
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }

            }

            // Add server items to browser
            for (let index = 0; index < serverItems.length; index++) {

                const item = serverItems[index];

                const exists = browserItems.find((entry) => entry.id == item.id);

                if (!exists) {
                    browserItems.push({
                        id: item.id,
                        title: item.title,
                        link: item.link,
                        category: item.category,
                        image: item.image
                    });
                    this.saveList(browserItems);
                }

            }

        } catch (error) {
            console.log(error);
        }
    }

    displayMessage(message) {

        if (!this.modal) {
            return;
        }

        this.modal.querySelector('#read-later-messsage').textContent = message;
        this.modal.showModal();
        window.setTimeout(() => {
            this.modal.close();
        }, 5000);
    }
}

export {
    ReadingList as
        default
};
